import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { CustomSnackbarComponent } from '../../shared/components/custom-snackbar/custom-snackbar.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-create-presentation-modal',
  templateUrl: './create-presentation-modal.component.html',
  styleUrls: ['./create-presentation-modal.component.scss']
})
export class CreatePresentationModalComponent implements OnInit {

  tapSelected = 'styles';

  showTextAutosaved = false;
  showDownloadPptButton = false;

  selectedInfluencersResponse = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
  }

  onInfluencerIdsSelected(influencersIds: string[]) {
    this.selectedInfluencersResponse = influencersIds;
    console.log('influencers response', this.selectedInfluencersResponse);
    this.showDownloadPptButton = this.selectedInfluencersResponse.length > 0;
  }

  showAutosaved() {
    this.showTextAutosaved = true;
    setTimeout( () => { this.showTextAutosaved = false; }, 4000 );
  }

  downloadPpt() {
    console.log('hola');
  }

  canActivateDownloadPpt(event) {
    console.log('que me viene en can activatedownload ppt button', event);
    this.showDownloadPptButton = event.length > 0;
  }

}