import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AmplifyService } from 'aws-amplify-angular';
import { Subscription } from 'rxjs';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { environment } from 'src/environments/environment';
import {TranslateService} from '@ngx-translate/core';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-step-styles',
  templateUrl: './step-styles.component.html',
  styleUrls: ['./step-styles.component.scss']
})
export class StepStylesComponent implements OnInit, OnDestroy {

  @Output() influencerIdsSelected = new EventEmitter<string[]>();
  @Output() showAutosaved = new EventEmitter<string[]>();
  @Output() changeToCampaignPlanTap = new EventEmitter<string[]>();
  @Input() campaignId;

  error_upload_brand_logo = '';
  presentation_logo = '';

  loading = false;

  selectedImage = {
    url: '',
    slideNumber: 0
  };

  themeSelected = {
    custom_logo: '',
    template: '',
    primary_color: '',
    secondary_color: '',
    preview_slides: [],
    influencers: [],
  };

  themeOptionsForm: FormGroup;

  @ViewChild('carousel', { static: false }) carousel: ElementRef;

  private subscriptions: Subscription[] = [];
  
  constructor(
    private _amplifyService: AmplifyService,
    private _campaignService: CampaignsService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.themeOptionsForm = new FormGroup({
      'primaryColor': new FormControl(''),
      'secondaryColor': new FormControl(''),
      'themeSelected': new FormControl('')
    });
   }

  ngOnInit() {
    this.getConfiguration();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getConfiguration() {
    this.loading = true;
    this._campaignService.getCampaignPresentationThemes(this.campaignId).subscribe( response => {
      this.themeSelected = response.body;
      this.themeOptionsForm.controls.primaryColor.setValue(this.themeSelected.primary_color);
      this.themeOptionsForm.controls.secondaryColor.setValue(this.themeSelected.secondary_color);
      this.themeOptionsForm.controls.themeSelected.setValue(this.themeSelected.template);
      this.presentation_logo = this.themeSelected.custom_logo;
      this.updateColorPreview(this.themeSelected.primary_color, 'primary');
      this.updateColorPreview(this.themeSelected.secondary_color, 'secondary');
      this.selectedImage = this.themeSelected.preview_slides[0];
      this.influencerIdsSelected.emit(this.themeSelected.influencers);
      this.loading = false;
    }, () => {
      setTimeout( () => { this.dialog.closeAll(); }, 1000 );
      this.snackBar.openFromComponent(CustomSnackbarComponent, {
        data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: 'error-snackbar',
      });
    });
  }
  
  // Método para activar el input file oculto
  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  getExtensionFromImage(file_type: string): string {
    return file_type.replace('image/', '.');
  }

  getUrlImageWithTime(url_image) {
    if (url_image !== 'n/a') {
      return url_image + '?ts=' + new Date().getTime();
    } else {
      return url_image;
    }
  }

  onFileSelected(fileInput: any): void {
    const file = fileInput.target.files[0];
    if (file.size <= 5242880) {
    if (
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg'
    ) {
      const extension = this.getExtensionFromImage(
          file.type
      );
      const config = {
        bucket: environment.aws_resources.s3.buckets.presentation_logo,
        region: environment.aws_resources.region,
        level: 'public',
        customPrefix: {
          public: '',
        },
      };
      this._amplifyService
          .storage()
          .vault.put(this.campaignId  + '/' + this.themeSelected.template + extension, file, config)
          .then(() => {
            const url =
                'https://s3-eu-west-1.amazonaws.com/' +
                config.bucket +
                '/' +
                this.campaignId + '/' + this.themeSelected.template +
                extension;
            const body = {
              presentation_logo: url,
            };
            // el presentation_logo lo hice para pruebas ahora
            this.presentation_logo = url;
            // this.dataForUpdate['brand_logo'] = url;
            // this.campaignData['brand_logo'] = this.getUrlImageWithTime(url);
            this.saveConfiguration(false);
          })
          .catch(() => {
            this.error_upload_brand_logo =
                'Oops, something went wrong. Please try again later.';
          });
    } else {
      this.error_upload_brand_logo =
          'Unsupported file type. Supported file types are: jpg, jpeg and png';
    }
  } else {
    this.error_upload_brand_logo = 'The file exceeds 5MB';
  }
}


  onImageClick(image) {
    this.selectedImage = image;
    this.scrollToSelectedImage();
  }

  updateColorPreview(color: string, input) {
    // Usa setTimeout para asegurar que el DOM está listo
    setTimeout(() => {
      let previewElement: any;
      if (input === 'primary'){
        previewElement = document.getElementById('colorPreviewPrimary') as HTMLElement;
      } else  {
        previewElement = document.getElementById('colorPreviewSecondary') as HTMLElement;
      }

      if (previewElement && this.isValidHex(color)) {
        previewElement.style.backgroundColor = color;
      } else if (previewElement) {
        previewElement.style.backgroundColor = 'transparent'; // O un color predeterminado
      }
    });
  }

  isValidHex(hex: string): boolean {
    return /^#([0-9A-F]{3}){1,2}$/i.test(hex);
  }

  onInput(event: Event, inputName) {
    const input = event.target as HTMLInputElement;
    this.updateColorPreview(input.value, inputName);
  }


  changeCarrouselImage(position: 'next' | 'previous') {
    const currentIndex = this.themeSelected.preview_slides.findIndex(image => image.slideNumber === this.selectedImage.slideNumber);

    if (currentIndex === -1) {
      return;
    }

    let newIndex = position === 'next' ? currentIndex + 1 : currentIndex - 1;

    // Evita navegación fuera de los límites
    if (newIndex < 0 || newIndex >= this.themeSelected.preview_slides.length) {
      return; // No hace nada si el índice está fuera de los límites
    }

    this.selectedImage = this.themeSelected.preview_slides[newIndex];
    this.scrollToSelectedImage();
  }


  scrollToSelectedImage() {
    const carouselElement = this.carousel.nativeElement as HTMLElement;
    const selectedImageElement = Array.from(carouselElement.querySelectorAll('img')).find(
      (img: HTMLImageElement) => img.src === this.selectedImage.url
    );

    if (selectedImageElement) {
      // Calcula la posición del elemento seleccionado
      const elementLeft = selectedImageElement.getBoundingClientRect().left;
      const carouselLeft = carouselElement.getBoundingClientRect().left;
      const scrollLeft = carouselElement.scrollLeft;

      // Ajusta el scroll del contenedor para centrar el elemento seleccionado
      carouselElement.scrollTo({
        left: scrollLeft + (elementLeft - carouselLeft) - (carouselElement.clientWidth / 2) + (selectedImageElement.clientWidth / 2),
        behavior: 'smooth'
      });
    }
  }

  isFirstImage(): boolean {
    return this.themeSelected.preview_slides.findIndex(image => image.slideNumber === this.selectedImage.slideNumber) === 0;
  }

  isLastImage(): boolean {
    return this.themeSelected.preview_slides.findIndex(image => image.slideNumber === this.selectedImage.slideNumber) === this.themeSelected.preview_slides.length - 1;
  }

  saveConfiguration(defaultConfiguration) {
    const request = {
      "template": this.themeOptionsForm.controls.themeSelected.value,
      "primary_color": this.themeOptionsForm.controls.primaryColor.value,
      "secondary_color": this.themeOptionsForm.controls.secondaryColor.value,
      "custom_logo": this.presentation_logo,
      "default_configuration": defaultConfiguration
    }
    this.subscriptions.push(
      this._campaignService.updateConfigurationPresentation(this.campaignId, request).subscribe(() => {
        this.showAutosaved.emit();
        console.log('que sacamos', defaultConfiguration);
        if (defaultConfiguration) {
          this.getConfiguration();
        }
      }, () => {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar',
        });
      })
    );
  }

  resetConfiguration() {
    this.saveConfiguration(true);
  }

  resetBrandLogo() {
    this.presentation_logo =  '';
    this.saveConfiguration(false);
  }

  changeToInfluencerTap() {
    this.changeToCampaignPlanTap.emit();
  }
}
