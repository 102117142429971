import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Sort } from '@angular/material';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import {TranslateService} from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step-campaign-plan',
  templateUrl: './step-campaign-plan.component.html',
  styleUrls: ['./step-campaign-plan.component.scss']
})
export class StepCampaignPlanComponent implements OnInit, OnDestroy {

  @Input() influencers;
  @Input() influencerIdsSelected;
  @Input() campaignId;

  @Output() activateDownloadPPT = new EventEmitter<string[]>();

  influencersForShow = [];
  influencersCheckboxSelected = [];
  
  influencersUsernameForm: FormGroup;
  influencers_checkbox_form: FormGroup;
  all_influencers_checkbox_form: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(
    private _campaignService: CampaignsService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
  ) { 
    this.influencersUsernameForm = new FormGroup({
      'username': new FormControl(''),
    });
    this.influencers_checkbox_form = new FormGroup({});
    this.all_influencers_checkbox_form = new FormGroup({
      'checkbox': new FormControl(false)
  });
  }

  ngOnInit() {
    this.influencersForShow = this.influencers;
    this.influencersForShow.forEach((influencer, index) => {
    // Verificar si el influencer_id está en el array influencerIdsSelected
    const isSelectedPresentation = this.influencerIdsSelected.includes(influencer.influencer_id);
    // Asignar true o false basado en la verificación
    influencer.isSelectedPresentation = isSelectedPresentation;
      this.influencers_checkbox_form.setControl('influencer_' + index, new FormControl(influencer.isSelectedPresentation));
    });
    // Recorrer el array influencerIdsSelected y llenar influencersCheckboxSelected con objetos que incluyan influencer_id
    this.influencerIdsSelected.forEach((id) => {
      this.influencersCheckboxSelected.push({
        influencer_id: id,
        // Puedes agregar más propiedades a los objetos si las necesitas
        isSelectedPresentation: true // Por ejemplo, una propiedad para el checkbox
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  selectInfluencerCheckbox(event, influencersForShow) {
    let influencersForSelect = {
      checked: undefined,
      influencersForShow: [],
    };
    influencersForSelect['checked'] = event.checked;
    if (Array.isArray(influencersForShow)) {
    this.influencersCheckboxSelected = [];
      influencersForShow.forEach((influencer, index) => {
        this.influencers_checkbox_form.controls['influencer_' + index].setValue(event.checked);
        influencersForSelect.influencersForShow.push({
          influencer_id: influencer.influencer_id,
          network: influencer.network,
          full_name: influencer.full_name,
          profile_pic_url: influencer.profile_pic_url,
          username: influencer.username,
          state: influencer.state,
          client_approval: influencer.client_approval,
        });
        influencer.isSelectedPresentation = event.checked;
      });
    } else {
      influencersForSelect.influencersForShow.push({
        influencer_id: influencersForShow.influencer_id,
        network: influencersForShow.network,
        full_name: influencersForShow.full_name,
        profile_pic_url: influencersForShow.profile_pic_url,
        username: influencersForShow.username,
        state: influencersForShow.state,
        client_approval: influencersForShow.client_approval,
      });
      influencersForShow.isSelectedPresentation = event.checked;
    }
    this.calledAddSelectInfluencers(influencersForSelect);
  }


  calledAddSelectInfluencers(event) {
    if (event.checked) {
      event.influencersForShow.forEach(influencer => {
        this.influencersCheckboxSelected.push(influencer);
      });
    } else {
      event.influencersForShow.forEach(influencer => {
        this.influencersCheckboxSelected = this.influencersCheckboxSelected.filter(item => item.influencer_id !== influencer.influencer_id);
      });
    }
    this.saveConfiguration();
  }

  redirectInfluencerNetwork(influencer) {
    if (influencer.network === 'instagram') {
      window.open('https://www.instagram.com/' + influencer.username, '_blank');
    } else if (influencer.network === 'youtube') {
      window.open(' https://www.youtube.com/channel/' + influencer.influencer_id, '_blank');
    } else if (influencer.network === 'tiktok') {
      window.open('https://www.tiktok.com/@' + influencer.username, '_blank');
    }
  }


  setVirtualScrollHeight(array_length) {
    return (464).toString() + 'px';
    // if (array_length >= 10) {
    //   // return 300  - array_length + 484 + 'px' ;
    //   return (460).toString() + 'px';
    //   // return (array_length * 90 - array_length + 84).toString() + 'px';
    // } else {
    //   // return (array_length * 90 - array_length + 84).toString() + 'px';
    //   return (460).toString() + 'px';
    // }
  }


  filterInfluencersByUsername() {
    const influencerssUsername = this.influencersUsernameForm.controls.username.value.toLowerCase();
    this.influencersForShow = this.filterInfluencernByUsername(this.influencers, influencerssUsername);
  }

  filterInfluencernByUsername(influencers: any[], username: string): any[] {
    return this.influencers.filter(influencer => influencer.username.toLowerCase().includes(username));
  }


  sortData(sort: Sort) {
    const data = this.influencersForShow.slice();
    if (!sort.active || sort.direction === '') {
      this.influencersForShow = data;
    }
    this.influencersForShow = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username': return this.compare(a.username, b.username, isAsc);
        case 'network': return this.compare(a.network, b.network, isAsc);
        case 'followed_by_count': return this.compare(a.followed_by_count, b.followed_by_count, isAsc);
        case 'engagement_rate': return this.compare(a.engagement_rate, b.engagement_rate, isAsc);
        default: return 0;
    }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  saveConfiguration() {
    const request = {
      influencers: this.influencersCheckboxSelected.map(influencer => influencer.influencer_id),
    };
    this.subscriptions.push(
      this._campaignService.updateConfigurationPresentation(this.campaignId, request).subscribe(() => {
        this.activateDownloadPPT.emit(request.influencers);
      }, () => {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar',
        });
      })
    );
  }
}
