import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CampaignsService} from '../../../../../../../../services/campaigns.service';
import {Subscription} from 'rxjs';
import {CampaignsModalComponent} from '../../../../../campaigns-modal/campaigns-modal.component';
import {MatDialog, MatSnackBar, Sort} from '@angular/material';
import {Router} from '@angular/router';
import {RemoveInfluencerComponent} from '../../../../../remove-influencer/remove-influencer.component';
import {ModalRejectReasonComponent} from '../../../../../../../share/shared-campaign/modals/modal-reject-reason/modal-reject-reason.component';
import {environment} from '../../../../../../../../../environments/environment';
import {openPlansDialog} from '../../../../../../../../store/app/app.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../../../store';
import {CustomSnackbarComponent} from '../../../../../../../../features/shared/components/custom-snackbar/custom-snackbar.component';
import { ShowReasonInfluencerRejectedModalComponent } from 'src/app/components/share/shared-campaign/modals/show-reason-influencer-rejected-modal/show-reason-influencer-rejected-modal.component';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-campaign-influencers-row',
  templateUrl: './campaign-influencers-row.component.html',
  styleUrls: ['./campaign-influencers-row.component.scss']
})
export class CampaignInfluencersRowComponent implements OnInit, OnDestroy {

  @Input() influencers;
  @Input() campaignId;
  @Input() network;
  @Input() currency_code;
  @Input() currency_symbol;
  @Input() viewport;
  @Input() isShare;
  @Input() is_internal_report;
  @Input() stateInfluencers;
  @Input() planNameShown;
  @Input() CAN_UNIQUE_AUDIENCE;
  

  @Output() callOpenProfile = new EventEmitter<any>();
  @Output() callRefreshChanges = new EventEmitter<any>();
  @Output() callGetCampaign = new EventEmitter<any>();
  @Output() callAddSelectInfluencer = new EventEmitter<any>();

  @ViewChild('total_cost_input', { static: false }) total_cost_input: ElementRef;

  influencers_checkbox_form: FormGroup;
  all_influencers_checkbox_form: FormGroup;
  private subscriptions: Subscription[] = [];


  constructor(
      private _campaignsService: CampaignsService,
      private dialog: MatDialog,
      private router: Router,
      private store: Store<State>,
      private snackBar: MatSnackBar,
      private translate: TranslateService,
      
  ) {
    this.influencers_checkbox_form = new FormGroup({});
    this.all_influencers_checkbox_form = new FormGroup({
      'checkbox': new FormControl(false)
  });
  }

  ngOnInit() {
    this.influencers.forEach((influencer, index) => {
      influencer.isSelected = false;
      this.influencers_checkbox_form.setControl('influencer_' + index, new FormControl(influencer.isSelected = false));
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  checkPositive(event: any) {
    const input = event.target as HTMLInputElement;
    if (Number(input.value) < 0) {
        input.value = "0";
    }
  }


  setVirtualScrollHeight(array_length) {
    if (this.viewport === 'handset') {
      if (array_length >= 10) {
        return '950px';
      } else {
        return (array_length * 420).toString() + 'px';
      }
    } else {
      if (array_length >= 10) {
        // return 300  - array_length + 484 + 'px' ;
        return (400).toString() + 'px';
        // return (array_length * 90 - array_length + 84).toString() + 'px';
      } else {
        return (array_length * 90 - array_length + 84).toString() + 'px';
      }
    }
  }

  validateInput() {
    // Obtén la referencia al elemento de entrada
    const inputElement = this.total_cost_input.nativeElement;
    let valor = inputElement.value;

    // Elimina todos los caracteres no numéricos, excepto comas y puntos
    valor = valor.replace(/[^\d,.]/g, '');

    // Reemplaza comas por vacío para que no afecten el formato
    valor = valor.replace(/,/g, '');

    // Divide la cadena en partes utilizando el punto como separador decimal
    const partes = valor.split('.');

    if (partes.length > 2) {
      // Si hay más de un punto decimal, elimina los extras
      valor = partes[0] + '.' + partes.slice(1).join('');
    } else {
      // Formatea la parte entera con comas como separadores de miles (estilo americano)
      partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Combina la parte entera y decimal con el punto como separador decimal
      valor = partes.join('.');
    }

    // Actualiza el valor del elemento de entrada
    inputElement.value = valor;
  }


  validateEntry(event: any): boolean {
    const inputChar = String.fromCharCode(event.charCode);
    // Permitir solo números, punto decimal, comas como separadores de miles y teclas de control (como retroceso)
    return /^[\d,.]+$/.test(inputChar) || event.charCode === 0 || event.charCode === 8;
  }

  openProfile(influencer_id, network) {
    this.callOpenProfile.emit({influencer_id, network});
  }

  setInfluencerCost(priceField, influencer) {
    const priceWithoutCommas = priceField.value.replace(/,/g, '');

    if (priceWithoutCommas !== '') {
      const influencerNewTotalCost = Number(priceWithoutCommas).toFixed(2);

      const influencerInitialTotalCost = influencer.correction_index === 0
          ? Number(influencer.total_cost / 1)
          : Number(influencer.total_cost / influencer.correction_index).toFixed(2);

      if (influencerInitialTotalCost === 0) {
        influencer.correction_index = 1;
      } else {
        influencer.correction_index = Number(influencerNewTotalCost) / Number(influencerInitialTotalCost);
      }

      influencer.total_cost = influencerNewTotalCost;
      this.callRefreshChanges.emit();
      this.updateCorrectionIndex(influencer.influencer_id, influencer.correction_index);
    }
  }


  updateCorrectionIndex(influencer_id, correction_index) {
    const formated_correction_index = Number(correction_index.toFixed(12));
    this._campaignsService
        .updateCorrectionIndex(
            this.campaignId,
            influencer_id,
            formated_correction_index
        )
        .subscribe();
  }

  updateNumberInfluencerMedias(typeMedia, number, influencer) {
    const influencers = [];
    const serviceKey = `${influencer.network}_services`;

    switch (typeMedia) {
      case 'posts':
      case 'stories':
      case 'reels':
        influencer[serviceKey][typeMedia] = Number(number.value);
        break;
      case 'videos':
      case 'mentions':
      case 'tiktok_videos':
        influencer[serviceKey][typeMedia] = Number(number.value);
        break;
      default:
        console.error(`Unsupported media type: ${typeMedia}`);
        return;
    }
    const influencerForUpdate = {
      influencer_id: influencer.influencer_id,
      network: influencer.network,
      posts: influencer[serviceKey].posts,
      stories: influencer[serviceKey].stories,
      reels: influencer[serviceKey].reels,
      videos: influencer[serviceKey].videos,
      mentions: influencer[serviceKey].mentions,
      tiktok_videos: influencer[serviceKey].tiktok_videos
    };

    influencers.push(influencerForUpdate);

    this.subscriptions.push(
        this._campaignsService.updateCampaignPostsStories(this.campaignId, influencers).subscribe(
            result => {
              // Manejar el resultado si es necesario
            },
            error => {
              // Manejar el error si es necesario
            }
        )
    );

    this.callRefreshChanges.emit();
  }



  updateAllNumberInfluencersMedia(action, nameMedia) {
    const influencersForUpdate = this.influencers
        .filter(influencer => {
          const isCorrectNetwork = influencer.network === 'instagram' && (nameMedia === 'stories' || nameMedia === 'posts') ||
              influencer.network === 'youtube' && (nameMedia === 'mentions' || nameMedia === 'videos') ||
              influencer.network === 'tiktok' && nameMedia === 'tiktok_videos';

          if (isCorrectNetwork) {
            const serviceKey = `${influencer.network}_services`;

            if (action === 'add') {
              influencer[serviceKey][nameMedia] += 1;
            } else if (action === 'subtract' && influencer[serviceKey][nameMedia] > 0) {
              influencer[serviceKey][nameMedia] -= 1;
            }

            return true;
          }

          return false;
        })
        .map(influencer => {
          const serviceKey = `${influencer.network}_services`;
          return {
            influencer_id: influencer.influencer_id,
            network: influencer.network,
            posts: influencer[serviceKey].posts,
            stories: influencer[serviceKey].stories,
            reels: influencer[serviceKey].reels,
            mentions: influencer[serviceKey].mentions,
            videos: influencer[serviceKey].videos,
            tiktok_videos: influencer[serviceKey].tiktok_videos
          };
        });

    this.subscriptions.push(
        this._campaignsService.updateCampaignPostsStories(this.campaignId, influencersForUpdate).subscribe(
            result => {
              // Manejar el resultado si es necesario
            },
            error => {
              // Manejar el error si es necesario
            }
        )
    );

    this.callRefreshChanges.emit();
  }


  switchInfluencer(influencer) {
    const influencers = [];
    influencers.push({
      influencer_id: influencer.influencer_id,
      current_state: influencer.state
    })
    this.subscriptions.push(
        this._campaignsService
            .updateInfluencerState(this.campaignId, influencers)
            .subscribe(
                () => {
                  this.callGetCampaign.emit();
                },
                () => {
                  this.snackBar.openFromComponent(CustomSnackbarComponent, {
                    data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
                    duration: 3000,
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    panelClass: 'error-snackbar',
                  });
                }
            )
    );

  }

  openViewInfluencerCanceledReason(reason) {
    const dialogRef = this.dialog.open(ShowReasonInfluencerRejectedModalComponent, {
      width: '490px',
      height: '430px',
      autoFocus: false,
      panelClass: 'remove-padding',
      data: {
        reason: reason,
      }
    });
  }

  openApproveCancelInfluencerModal(influencer, action) {
    const dialogRef = this.dialog.open(ModalRejectReasonComponent, {
      width: '490px',
      height: '430px',
      autoFocus: false,
      panelClass: 'remove-padding',
      data: {
        influencer_id: influencer.influencer_id,
        campaign_id: this.campaignId,
        componentCalled: action,
        isShare: this.isShare,
        username: influencer.username,
        network: influencer.network
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status != null) {
        if (result.status === 200) {
          this.callGetCampaign.emit();
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: result.body.message, type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
        } else {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: 'Oops something went wrong, please try again.', type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
        }
      }
    });
  }


  openRemoveInfluencerDialog(influencer_id, username): void {
    const dialogRef = this.dialog.open(RemoveInfluencerComponent, {
      width: '490px',
      height: '320px',
      panelClass: 'remove-padding',
      autoFocus: false,
      data: {
        campaign_id: this.campaignId,
        influencer_id: influencer_id,
        username: username,
        network: this.network
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status != null) {
        if (result.status === 200) {
          this.callGetCampaign.emit();
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: result.body.message, type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
        } else {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: 'Oops something went wrong, please try again.', type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
        }
      }
    });
  }

  editAudience(influencer_id): void {
    this.router.navigate(['app/editAudience'], {
      queryParams:
          {
            influencerId: influencer_id,
            campaignId: this.campaignId
          }
    });
  }

  openCampaignsDialog(influencer): void {
    this.dialog.open(CampaignsModalComponent, {
      width: '790px',
      height: '350px',
      panelClass: 'remove-padding',
      autoFocus: false,
      data: {
        instagram_id: influencer.influencer_id,
        network: influencer.network,
        full_name: influencer.full_name,
        profile_pic_url: influencer.profile_pic_url,
        username: influencer.username,
      },
    });
  }


  hideInfluencer(influencersForUpdate) {
    const influencer_ids = [];
    influencer_ids.push(influencersForUpdate.influencer_id)
    this.subscriptions.push(
        this._campaignsService.hideInfluencerCampaign(this.campaignId, influencer_ids).subscribe( response => {
          if ( influencersForUpdate.hide_influencer === true ) {
            influencersForUpdate.hide_influencer = false;
          } else if ( influencersForUpdate.hide_influencer === false ) {
            influencersForUpdate.hide_influencer = true;
          }
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: response.body.message, type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
        }, () => {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
        })
    );
  }

  redirectInfluencerNetwork(influencer) {
    if (influencer.network === 'instagram') {
      window.open('https://www.instagram.com/' + influencer.username, '_blank');
    } else if (influencer.network === 'youtube') {
      window.open(' https://www.youtube.com/channel/' + influencer.influencer_id, '_blank');
    } else if (influencer.network === 'tiktok') {
      window.open('https://www.tiktok.com/@' + influencer.username, '_blank');
    }
  }

  unlockUniqueAudience() {
    if ( environment.platform_id === '11posts' ) {
      this.store.dispatch(openPlansDialog());
    }
  }


  selectInfluencerCheckbox(event, influencers) {
    let influencersForSelect = {
      checked: undefined,
      influencers: [],
    };
    influencersForSelect['checked'] = event.checked;

    if (Array.isArray(influencers)) {
      influencers.forEach((influencer, index) => {
        this.influencers_checkbox_form.controls['influencer_' + index].setValue(event.checked);
        influencersForSelect.influencers.push({
          influencer_id: influencer.influencer_id,
          network: influencer.network,
          full_name: influencer.full_name,
          profile_pic_url: influencer.profile_pic_url,
          username: influencer.username,
          state: influencer.state,
          client_approval: influencer.client_approval,
        });
        influencer.isSelected = event.checked;
      });
    } else {
      influencersForSelect.influencers.push({
        influencer_id: influencers.influencer_id,
        network: influencers.network,
        full_name: influencers.full_name,
        profile_pic_url: influencers.profile_pic_url,
        username: influencers.username,
        state: influencers.state,
        client_approval: influencers.client_approval,
      });
      influencers.isSelected = event.checked;
    }
    this.callAddSelectInfluencer.emit(influencersForSelect);
  }


  sortData(sort: Sort) {
    const data = this.influencers.slice();
    if (!sort.active || sort.direction === '') {
      this.influencers = data;
    }
    this.influencers = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username': return this.compare(a.username, b.username, isAsc);
        case 'followed_by_count': return this.compare(a.followed_by_count, b.followed_by_count, isAsc);
        case 'engagement_rate': return this.compare(a.engagement_rate, b.engagement_rate, isAsc);
        case 'total_cost': return this.compare(a.total_cost, b.total_cost, isAsc);
        case 'unique_percentage': return this.compare(a.unique_percentage, b.unique_percentage, isAsc);
        case 'state': return this.compare(a.state, b.state, isAsc);
        default: return 0;
    }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
